// ** React Imports
import { useState, createContext } from 'react'
import { useSelector } from 'react-redux'

// ** Create Context
const GlobalLoader = createContext()

// ** Componentes
import FallbackSpinnerComponent from '@components/spinner/Fallback-spinner'

const GlobalLoaderContext = ({ children }) => {
  // ** State
  const [is_loading, setIsLoading] = useState(false)

  const is_store_loading = useSelector(store => store.loader.loading)

  return (
    <GlobalLoader.Provider value={{ is_loading, setIsLoading }}>
        {(is_loading || is_store_loading) && <FallbackSpinnerComponent fixed />}
        <div className={`${(is_loading || is_store_loading) ? 'loading-body' : ''}`}>
          {children}
        </div>
    </GlobalLoader.Provider>
  ) 
}

export { GlobalLoader, GlobalLoaderContext }
