// ** React Imports
import { useState, createContext } from 'react'

// ** Create Context
const ClientContext = createContext()

const ClientProvider = ({ children }) => {
    
    const [has_first_schedule_modal_seen, setHasFirstScheduleModalSeen] = useState(false)

    const value = {
        has_first_schedule_modal_seen,
        setHasFirstScheduleModalSeen
    }

    return (
        <ClientContext.Provider value={value}>
            {children}
        </ClientContext.Provider>
    ) 
}

export { ClientContext, ClientProvider }
