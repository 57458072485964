// ** Core JWT Import
import useJwt from '@src/@core/auth/jwt/useJwt'

const authConfig = {
  loginEndpoint: process?.env?.REACT_APP_API_URL + '/auth/acessar',
  // logoutEndpoint: "/jwt/logout"
  refreshEndpoint: process?.env?.REACT_APP_API_URL + '/auth/refresh-token',
  registerEndpoint: process?.env?.REACT_APP_API_URL + '/auth/registrar',
  storageRefreshTokenKeyName: "refreshToken",
  storageTokenKeyName: "accessToken"
  // tokenType: "Bearer"
}

const { jwt } = useJwt(authConfig)

export default jwt
